/******* screen-medium.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.desk {
  max-width: 90%;
}
#home {
  width: 220px;
  height: 62px;
  margin: 37px 0;
}
.cb-layout1 #head {
  left: 5%;
  width: 90%;
}
.cb-layout1 .section--terzo.section--black {
  min-height: 640px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
.downlink {
  width: 70px;
  height: 70px;
  margin-left: -35px;
  bottom: 64px;
}
.downlink:hover {
  bottom: 44px;
}
#mobile-navi {
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
}
.togglenavigation {
  right: 5%;
}
.naviani {
  padding: 40px 0;
}
.mobhome {
  width: 198px;
}
@media (max-width: 1024px) {
  h1 {
    font-size: 9.1796875vw;
  }
}
@media (max-width: 1770px) {
  h2 {
    font-size: 5.31073446vw;
  }
}
@media (max-width: 1024px) {
  #root #disp.zoom div.body .cb-zoom-image {
    max-height: 85% !important;
    max-width: 85% !important;
  }
}
.section--primo .area {
  width: 100%;
}
.section--primo .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--primo .area .foot {
  margin-right: 2.63157895%;
  margin-left: 2.63157895%;
}
.section--primo .area .part {
  margin-right: 2.63157895%;
  margin-left: 2.63157895%;
  width: 94.73684211%;
}
.section--primo .area .tiny {
  width: 44.73684211%;
}
.section--primo .area > .slim .part {
  width: 94.73684211%;
}
.section--secondo .area {
  width: 100%;
}
.section--secondo .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--secondo .area .foot {
  margin-right: 1.77725118%;
  margin-left: 1.77725118%;
}
.section--secondo .area .part {
  margin-right: 1.77725118%;
  margin-left: 1.77725118%;
  width: 96.44549763%;
}
.section--secondo .area .tiny {
  width: 46.44549763%;
}
.section--secondo .area > .slim {
  width: 50%;
}
.section--secondo .area > .slim .foot,
.section--secondo .area > .slim .part {
  margin-right: 3.55450237%;
  margin-left: 3.55450237%;
}
.section--secondo .area > .slim .part {
  width: 92.89099526%;
}
.section--secondo .area > .slim .tiny {
  width: 92.89099526%;
}
.section--terzo .area {
  width: 100%;
}
.section--terzo .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--terzo .area .foot {
  margin-right: 1.77725118%;
  margin-left: 1.77725118%;
}
.section--terzo .area .part {
  margin-right: 1.77725118%;
  margin-left: 1.77725118%;
  width: 96.44549763%;
}
.section--terzo .area .tiny {
  width: 46.44549763%;
}
.section--terzo .area > .slim {
  width: 50%;
}
.section--terzo .area > .slim .foot,
.section--terzo .area > .slim .part {
  margin-right: 3.55450237%;
  margin-left: 3.55450237%;
}
.section--terzo .area > .slim .part {
  width: 92.89099526%;
}
.section--terzo .area > .slim .tiny {
  width: 92.89099526%;
}
.section--quarto .area {
  width: 100%;
}
.section--quarto .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--quarto .area .foot {
  margin-right: 0.72639225%;
  margin-left: 0.72639225%;
}
.section--quarto .area .part {
  margin-right: 0.72639225%;
  margin-left: 0.72639225%;
  width: 98.5472155%;
}
.section--quarto .area .tiny {
  width: 48.5472155%;
}
.section--quarto .area > .slim {
  width: 50%;
}
.section--quarto .area > .slim .foot,
.section--quarto .area > .slim .part {
  margin-right: 1.4527845%;
  margin-left: 1.4527845%;
}
.section--quarto .area > .slim .part {
  width: 97.09443099%;
}
.section--quarto .area > .slim .tiny {
  width: 97.09443099%;
}
.section--primo .area {
  width: 105.55555556%;
  margin-left: -2.77777778%;
}
.section--terzo .area {
  width: 103.68550369%;
  margin-left: -1.84275184%;
}
.section--secondo .area {
  width: 103.68550369%;
  margin-left: -1.84275184%;
}
.section--quarto .area {
  width: 101.47420147%;
  margin-left: -0.73710074%;
}
.section--primo .area div.unit.slim {
  width: 75.43859649%;
  margin-left: 12.28070175%;
  margin-right: 12.28070175%;
}
.section--primo .area div.unit.slim div.part {
  margin-left: 3.48837209%;
  margin-right: 3.48837209%;
  width: 93.02325581%;
}
.section--secondo .area div.seam {
  margin-left: 1.77725118%;
  margin-right: 1.77725118%;
  width: 96.44549763%;
}
.section--secondo .area div.flat {
  margin-left: 1.77725118%;
  margin-right: 1.77725118%;
  width: 96.44549763%;
}
.section--secondo .area div.flat div.tall {
  float: right;
  width: 72.44897959%;
}
.section--secondo .area div.flat div.tiny {
  float: left;
  width: 19.89795918%;
}
.section--quarto .area div.seam {
  margin-left: 0.72639225%;
  margin-right: 0.72639225%;
}
.section--quarto .area div.seam.wide {
  width: 98.5472155%;
}
.section--quarto .area div.seam.wide div.part {
  margin-left: 1.84275184%;
  margin-right: 1.84275184%;
}
.section--quarto .area div.seam.wide div.part.tall {
  width: 96.31449631%;
}
.section--quarto .area div.seam.wide div.part.tiny {
  width: 46.31449631%;
}
.section--quarto .area div.seam.slim {
  width: 48.5472155%;
}
.section--quarto .area div.seam.slim div.part {
  margin-left: 3.74064838%;
  margin-right: 3.74064838%;
  width: 92.51870324%;
}
.cb-album div.ctrl div.prev {
  margin-left: 2.63157895%;
}
.cb-album div.ctrl div.next {
  margin-right: 2.63157895%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #000;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #000;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #000000;
}
.mese .same {
  background: none;
}
/******* module-filter-medium.less 2013-1-16 *******/
.cb-mobile-filter-items > .beta > span {
  width: 14.28571429%;
}
/*# sourceMappingURL=./screen-medium.css.map */